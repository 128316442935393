@media (max-width: 768px){
    .ant-layout-sider {
        flex: none !important;
        max-width: 74px !important;
        min-width: 74px !important;
        width: 60px !important;
        
    }
    .card_main {
        background-color: #093EAB;
        color: white;
        padding: 10px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
    }
    .ant-menu-item{
        /* padding-left: 37px !important; */
    }
    .ant-menu-submenu-arrow{
        inset-inline-end: 3px !important;
    }
    .logo_img_dashboard .ant-image{ 
        width: 66px !important;
    }
}